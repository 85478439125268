import React, { useState } from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import Spinner from '../components/spinner/Spinner'
import Checked from '../components/images/CheckedSVG'

const Contact = () => {
    const [emailContent, setContent] = useState({
        senderName: '',
        senderEmail: '',
        message: '',
        formPot: '',
        errorMessage: null,
    })

    const [isLoading,setLoading] = useState(false)
    const [isSent,setSent] = useState(false)

    const handleInputChange = e => {
        const {name, value} = e.target
        setContent(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    const handleSubmit = e => {
        e.preventDefault();

        setLoading(true);
        
        const body = JSON.stringify({
            senderName: emailContent.senderName,
            senderEmail: emailContent.senderEmail,
            message: emailContent.message,
        })

        const requestOptions = {
            method: "POST",
            body
        };

        if (emailContent.formPot) {
            setLoading(false)
            return
        } else {
            fetch(process.env.GATSBY_AWS_SES, requestOptions)
            .then(res => {
                console.log(res)
                if (!res.ok) {
                    setContent({ ...emailContent, errorMessage: 'An Error has occurred. Please try again.' })
                    setLoading(false)
                } else {
                    setLoading(false)
                    setSent(true)
                }
            }).then(() => {
                setTimeout(() => {
                    setSent(false)
                    setContent({
                        senderName: '',
                        senderEmail: '',
                        message: '',
                        errorMessage: null
                    })
                }, 2000)
            }).catch(err => {
                setContent({...emailContent, errorMessage:'An Error has occurred. Please try again.'})
            });
        }
        
    }

    return (
        <Layout>
            <SEO title="Contact Me" />
            <div className="mt-24">
                <div className="flex justify-center items-center">
                    <form onSubmit={handleSubmit} className="sm:w-1/4 md:w-1/3 lg:w-1/3 w-4/5">
                    <h1 className="font-bold mb-10"><span style={highlightText}>Shoot me a message</span></h1>
                        <div>
                            <label htmlFor="senderName">Your Name</label>
                            <input required type="text" name="senderName" autoComplete="off" value={emailContent.senderName} onChange={handleInputChange} className="w-full border-b border-primary-300 mt-2 mb-8 focus:outline-none focus:border-primary-500" />
                        </div>
                        <div>
                            <input className="hidden" type="text" name="formPot" autoComplete="off" value={emailContent.formPot} onChange={handleInputChange} />
                        </div>
                        <div>
                            <label htmlFor="senderEmail">Your Email</label>
                            <input required type="email" name="senderEmail" autoComplete="off" value={emailContent.senderEmail} onChange={handleInputChange} className="w-full border-b border-primary-300 mt-2 mb-8 focus:outline-none focus:border-primary-500" />
                        </div>
                        <div>
                            <label htmlFor="message">Your Message</label>
                            <textarea required name="message" autoComplete="off" value={emailContent.message} onChange={handleInputChange} className="w-full border border-primary-300 mt-2 mb-2 focus:outline-none focus:border-primary-500" style={{height:'8em'}} />
                        </div>
                    {emailContent.errorMessage ? <p style={{color:'red'}}>{emailContent.errorMessage}</p>: ""}
                        
                        <div className="flex justify-end text-right py-4 pr-4">
                            <div className="w-20 mx-10">
                                {isLoading ? <Spinner /> : ""}
                                {isSent ? <Checked style={{position:'relative',top:'5px', left:'3em', fill: '#e4e4e7'}} /> : ""}
                            </div>
                            <div>
                                <button type="submit" className="bg-primary-50 py-1 px-4 rounded hover:bg-primary-100 h-6">Send</button>        
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default Contact;

const highlightText = {
    background: "linear-gradient(to top, #ffff00cb 50%, transparent 50%)"
}