import React from 'react'

import './spinner.css'

export default function Spinner() {
    return (
        <div>
            <div className="lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    )
}
